export type Customer = {
  id: number;
  name: customerType;
  apiBackend: string;
  domain: string;
  userSuffix: string;
  enabled: boolean;
};

export type customerType =
  | 'marval'
  | 'buenvivir'
  | 'prodesa'
  | 'mayasoft'
  | 'gmail'
  | 'cusezar'
  | 'alcabama'
  | 'jaramillo_mora'
  | 'capital'
  | 'triada'
  | 'logicem'
  | 'fl_colombia'
  | 'bolivar_cali'
  | 'job_and_talent';

export const Customers = [
  'marval',
  'buenvivir',
  'prodesa',
  'mayasoft',
  'gmail',
  'cusezar',
  'alcabama',
  'jaramillo_mora',
  'capital',
  'triada',
  'logicem',
  'fl_colombia',
  'bolivar_cali',
  'job_and_talent',
];
