import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  DateRangePicker,
  Dialog,
  Grid, IconButton, Input, MenuItem, Select, SnackBar
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { Table } from 'components/organisms';
import { format } from 'date-fns';
import { useDialog, useDownloadFile, useGetTable } from 'hooks';
import useTable, { filterModelType, tableModelType } from 'hooks/useTable';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { Company, FlatHistory, PaginationType, Project } from 'types';
import { Consumption } from 'types/entities/consumption';
import { joinObjectWithFormat } from 'utils';
import { DATE_FORMAT_BACK_WITH_OUT_TIME, GET_COMPANIES, GET_PROJECTS } from '../../../../../const';
import useGetData from '../../../../../hooks/useGetData';
import { DateRangePickerValue } from '../../../../atoms/DateRangePicker/DateRangePicker';
import HistoryConsumptionTemplate from '../HistoryConsumptionTemplate';
import HistoryDownloadTemplate from '../HistoryDownloadTemplate';
import { actions } from '../HistoryPendingTemplate/HistoryPendingTemplate.const';
import HistoryUploadTemplate from '../HistoryUploadTemplate';
import HistoryTemplateApiLoading from '../Shared/HistoryTemplate.loading';
import HistoryTemplatePdf from '../Shared/HistoryTemplate.pdf';
import { AREAS } from '../Shared/sharedConsts';
import {
  createFilterModel,
  defaultPagination,
  defaultSortModel,
  downloadPdf,
  fetchProcess,
  getColumns, getDefaultHistoryTemplateFilter, getFileNameByInvoiceNumber
} from './HistoryTemplate.const';
import {
  HistoryTemplateFilterSchema,
  HistoryTemplateFilterType
} from './HistoryTemplate.schema';
import { HistoryTemplateProps } from './HistoryTemplate.types';

const HistoryTemplate = ({ ...props }: HistoryTemplateProps) => {

  const [waitingResponse, setWaitingResponse] = useState(false);

  const [action, setAction] = useState<
    actions
  >(
    undefined,
  );
  const [open, setOpen] = useState<severityType>();

  const [selected, onOpen, onCloseModal] = useDialog<number | undefined>(
    undefined,
  );
  const [consumptionList, setConsumptionList] = useState<Consumption[]>([]);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const [filterModel, setFilterModel] = useState<filterModelType<FlatHistory>[]>([]);

  const { data: projectsFetch, loading: loadingProjects } = useGetData<Project>(
    GET_PROJECTS
  );

  const { data: companiesFetch, loading: loadingCompanies } = useGetData<Company>(
    GET_COMPANIES
  );

  //TODO: arreglar 0 quemado
  let selectedId: number;
  let selectedInvoiceNumber: string;

  const dialogTitle = (): string => {
    if (action === "upload") {
      return "Carga el documento";
    }
    if (action === "download") {
      return "Descarga de facturas pagadas";
    }
    if (action === "consumption") {
      return "Información de consumos";
    }
    else {
      return "";
    }
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<HistoryTemplateFilterType>({
    defaultValues: getDefaultHistoryTemplateFilter(),
    resolver: yupResolver(HistoryTemplateFilterSchema),
  });

  const { invoiceNumber, paymentVoucher, area, projects, publicServices, dateRange } = watch();

  const confirmEdit = useCallback(
    (id: number) => {
      onOpen(id);
      setAction('edit');
    },
    [onOpen],
  );

  const onUpload = useCallback(
    () => {
      onOpen(0);
      setAction('upload');
    },
    [onOpen],
  );

  const onDownload = useCallback(
    () => {
      onOpen(0);
      setAction('download');
    },
    [onOpen],
  );


  const onDownloadOne = useCallback(
    () => {
      onOpen(0);
      setAction('downloadOne');
    },
    [onOpen],
  );

  const onViewConsumptionModal = useCallback(
    () => {
      onOpen(0);
      setAction('consumption');
    },
    [onOpen],
  );

  const onCloseSnackBar = useCallback(() => {
    setAction(undefined);
    setOpen(undefined);
  }, []);

  const onClose = useCallback(() => {
    setFile(undefined);
    onCloseModal();
  }, [onCloseModal]);

  const onViewConsumption = async (consumptions: Consumption[]) => {
    onViewConsumptionModal();
    setConsumptionList(consumptions);
  }


  const {
    loading,
    error,
    data: process,
    handleData,
    getData,
  } = useGetTable<PaginationType<FlatHistory>, tableModelType<FlatHistory>>(fetchProcess, {
    paginationModel: defaultPagination,
    sortModel: defaultSortModel,
    filterModel,
  });

  const tableControllers = useTable<FlatHistory>(
    {
      filterModel,
      paginationModel: process
        ? { ...defaultPagination, rowsCount: process.rowsCount }
        : defaultPagination,
    },
    handleData,
    getData as (
      param: Omit<tableModelType<FlatHistory>, 'paginationModel'>,
    ) => Promise<PaginationType<FlatHistory>>,
  );



  /**
   * TODO: delete this comment
   * Block of code to use the custom hook to download files
   */
  const [isDownloading, setIsDownloading] = useState(false);
  const preDownloading = () => {
    setIsDownloading(true);
  };
  const postDownloading = () => {
    setIsDownloading(false);
  };
  const onErrorDownloadFile = () => {
    onClose()
    setOpen('error');
  };
  const getFileName = () => {
    return getFileNameByInvoiceNumber(
      selectedInvoiceNumber || ""
    );
  };

  //TODO: corregir 0 quemado

  const [file, setFile] = useState<Blob | undefined>();

  const downloadFiles = () => {
    return downloadPdf(selectedId || 1)
  }

  const { ref, url, download, name, view } = useDownloadFile({
    apiDefinition: downloadFiles,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName,
  });

  const onDownloadSingleFile = async (id: number, invoiceNumber: string) => {
    try {
      selectedId = id;
      selectedInvoiceNumber = invoiceNumber;
      download();
    } catch (error) {
      setOpen('error');
      console.log(error)
    }
  }

  const onViewOne = async (id: number, invoiceNumber: string) => {
    onDownloadOne();
    try {
      selectedId = id;
      selectedInvoiceNumber = invoiceNumber;
      view().then(data => {
        setFile(data)
      });
    } catch (error) {
      setOpen('error');
      onClose();
      console.log(error)
    }
  }

  const columns = useMemo(
    () => getColumns(confirmEdit, onDownloadSingleFile, ref, url, name, onViewOne, onViewConsumption),
    [confirmEdit, onDownloadSingleFile, ref, url, name],
  );

  const onSubmit = (filterHistoryTemplate: HistoryTemplateFilterType) => {

    const {
      invoiceNumber,
      paymentVoucher,
      area,
      projects,
      publicServices,
      dateRange
    } = filterHistoryTemplate;

    tableControllers.paginationModel.page = 0;

    const modelFilter = createFilterModel({ invoiceNumber, paymentVoucher, area, projects, publicServices, dateRange });

    setFilterModel(modelFilter as filterModelType<FlatHistory>[]);
  };

  return (
    <>
      <Table<FlatHistory>
        {...tableControllers}
        numberOfVisibleColumns={4}
        columns={columns}
        rows={process?.result || []}
        error={!!error}
        loading={loading}
        exportOptions={{
          filename: `Reporte-históricos-de-pagos_${joinObjectWithFormat(dateRange, '-', format, [
            DATE_FORMAT_BACK_WITH_OUT_TIME,
          ])}`,
        }}
        filterComponent={
          <Grid
            container
            alignItems="center"
            justify="flex-end"
            spacing={4}
            wrap="nowrap"
          >
            <Grid item xs={12}>
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Grid item>
                  <Grid
                    container
                    alignItems="center"
                    justify="flex-end"
                    spacing={2}
                    wrap="nowrap"
                  >
                    <Grid item xs={2}>
                      <Input
                        fullWidth
                        label="Factura"
                        {...register('invoiceNumber')}
                        error={!!errors.invoiceNumber}
                        helperText={errors.invoiceNumber && errors.invoiceNumber.message}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Input
                        fullWidth
                        label="CE"
                        {...register('paymentVoucher')}
                        error={!!errors.paymentVoucher}
                        helperText={errors.paymentVoucher && errors.paymentVoucher.message}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="area"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              value={field.value}
                              onChange={field.onChange}
                              label="Área"
                              fullWidth
                              enableFilter
                            >
                              {AREAS
                                .sort((area1, area2) => (area1.name > area2.name) ? 1 : -1)
                                .map(({ value, name }) => (
                                  <MenuItem
                                    key={value}
                                    label={`${name}`}
                                    value={`${value}`}
                                  >
                                    {name}
                                  </MenuItem>
                                ))}
                            </Select>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="projects"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              value={field.value
                              }
                              onChange={field.onChange}
                              label="Proyectos"
                              fullWidth
                              multiple
                              enableFilter
                            >
                              {projectsFetch ?
                                projectsFetch
                                  .sort((proj1, proj2) => (proj1.name > proj2.name) ? 1 : -1)
                                  .map(({ id, name }) => (
                                    <MenuItem
                                      key={name}
                                      label={`${name}`}
                                      value={`${id}`}
                                    >
                                      {name}
                                    </MenuItem>
                                  )) : <></>}
                            </Select>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Controller
                        name="publicServices"
                        control={control}
                        render={({ field }) => {
                          return (
                            <Select
                              value={field.value
                              }
                              onChange={field.onChange}
                              label="Compañía"
                              fullWidth
                              multiple
                              enableFilter
                            >
                              {companiesFetch ?
                                companiesFetch
                                  .sort((comp1, comp2) => (comp1.name > comp2.name) ? 1 : -1)
                                  .map(({ id, name }) => (
                                    <MenuItem
                                      key={name}
                                      label={`${name}`}
                                      value={`${id}`}
                                    >
                                      {name}
                                    </MenuItem>
                                  )) : <></>}
                            </Select>
                          );
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="dateRange"
                        control={control}
                        render={({ field }) => (
                          <DateRangePicker
                            label="Fecha de pago"
                            fullWidth
                            onChange={field.onChange}
                            value={field.value as DateRangePickerValue}
                            error={!!errors.dateRange}
                            helperText={
                              errors.dateRange &&
                              (errors.dateRange.startDate?.message ||
                                errors.dateRange.endDate?.message)
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Filtrar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item>
              <IconButton
                info="Descargar reportes históricos"
                color="primary"
                onClick={() => onDownload()}
              >
                <AiOutlineCloudDownload />
              </IconButton>
            </Grid>
          </Grid >

        }
      />
      <Dialog
        open={selected !== undefined}
        onClose={onClose}
        title={dialogTitle()}
        maxWidth='md'
      >
        <>
          {!waitingResponse ? (
            <Grid container spacing={3} justify="flex-end">
              <Grid item xs={12}>
                {/*TODO: borrar este historyUpload template */}
                {action === 'upload' && (
                  <HistoryUploadTemplate
                    setWaitingResponse={setWaitingResponse}
                    waitingResponse={waitingResponse}
                    action={action}
                    setAction={setAction}
                    open={open}
                    setOpen={setOpen}
                    onClose={onClose}
                    onOpen={onOpen}
                    setErrorMessage={setErrorMessage}
                  />
                )}
                {action === 'download' && (
                  <HistoryDownloadTemplate
                    setOpen={setOpen}
                    onClose={onClose}
                    setErrorMessage={setErrorMessage}
                    historyDownloadFilterModel={{
                      invoiceNumber,
                      paymentVoucher,
                      area,
                      projects,
                      publicServices,
                      dateRange
                    }}
                    projectList={projectsFetch}
                    publicServicesList={companiesFetch}
                  />
                )}
                {action === 'consumption' && (
                  <HistoryConsumptionTemplate
                    setOpen={setOpen}
                    onClose={onClose}
                    setErrorMessage={setErrorMessage}
                    consumptionList={consumptionList}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {action === 'downloadOne' && (
                  <HistoryTemplatePdf fileView={file} />
                )}
              </Grid>
            </Grid>
          ) : (
            <HistoryTemplateApiLoading />
          )}
        </>
      </Dialog>

      <SnackBar
        wait={2000}
        open={open !== undefined}
        onClose={onCloseSnackBar}
        severity={open}
      >
        {open === 'success' && 'El proceso se realizó correctamente'}
        {open === 'warning' && errorMessage}
        {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
      </SnackBar>
    </>
  );
};

export default HistoryTemplate;
