import { Buffer } from 'buffer';
import { useCallback, useMemo, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';

import {
  HiDownload,
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
  HiOutlineChevronLeft,
  HiOutlineChevronRight
} from 'react-icons/hi';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Grid, IconButton, Typography } from 'components/atoms';
import {
  BasicTable,
  TableCell,
  TableRow,
  defaultRowType
} from 'components/molecules';

import Toggle from 'components/atoms/Toggle';
import React from 'react';
import { dataToHTMLTable } from 'utils';
import {
  Grow,
  InputPagination,
  Svg,
  TableContainer,
  TableFooter,
  TableHead,
  Title,
  Toolbar
} from './Table.styles';
import { TableProps, sortModelType } from './Table.types';
import { SortTable } from './components';

const uri = 'data:application/vnd.ms-excel;base64,';

// Se quita el tag meta utf y se arreglaron las tildes
const template = `
<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
<head>
<!--[if gte mso 9]>
    <xml>
        <x:ExcelWorkbook>
            <x:ExcelWorksheets>
                <x:ExcelWorksheet>
                    <x:Name>{worksheet}</x:Name>
                    <x:WorksheetOptions>
                        <x:DisplayGridlines/>
                    </x:WorksheetOptions>
                </x:ExcelWorksheet>
            </x:ExcelWorksheets>
        </x:ExcelWorkbook>
    </xml>
    <style>
      table { font-family: Arial, sans-serif; }
    </style>
    <![endif]-->
</head>
<body>{table}</body>
</html>`;

/**
 * Component to render a table with pagination, sorting and export options
 * To use the toggle option, the column must have the property enableToggle: true & the toggleOptions prop must be passed in the parent component
 * @param TableProps 
 * @returns 
 */

const CustomTable = <T extends defaultRowType>({
  columns,
  title,
  rows,
  onExportModel,
  onSortModel,
  handlePage,
  paginationModel,
  sortModel,
  exportOptions,
  rowsPerPageProps = {},
  filterComponent,
  numberOfVisibleColumns,
  loading,
  error,
  toggleOptions,
}: TableProps<T>) => {
  const ref = useRef<HTMLTableElement>(null);
  /**
   * 
   * @param node 
   * @param isDate
   * @param row 
   * @param field 
   * @param isCurrency 
   * @param isArea 
   * @param isState 
   * @param isStatus 
   * @param isString  intended to deal with string data that contains only numbers
   * @param isRaw
   * @param customExcelRender function to handle the row and return a custom string
   * @returns 
   */
  const transfromRenderToString = (
    node: React.ReactNode,
    isDate: boolean | undefined,
    row: defaultRowType | undefined,
    field: string | undefined,
    isCurrency: boolean | undefined,
    isArea: boolean | undefined,
    isState: boolean | undefined,
    isStatus: boolean | undefined,
    isString: boolean | undefined,
    isRaw: boolean | undefined,
    customExcelRender?: ((element: any) => string),
  ) => {
    let element = node;
    if (row && field && isDate) {
      return new Date(row[field]).toLocaleDateString('es-CO');
    }
    else if (row && field && isCurrency) {
      return new Number(row[field]);
    }
    else if (row && field && (isArea || isState || isRaw)) {
      return row[field];
    }
    else if (isStatus) {
      return isStatus ? 'ACTIVO' : 'INACTIVO';
    }
    else if (row && field && isString) {
      return (`${row[field]}` + '&nbsp;');
    }
    //If customExcelRender is defined, it will be used to render the cell with a custom function provided in the columns definition
    else if (row && customExcelRender) {
      return customExcelRender(row);
    }
    else {
      if (React.isValidElement(node)) {
        element = node.props.children;
      }
      return ReactDOMServer.renderToStaticMarkup(<div>{element}</div>);
    }
  };


  const exportTable = useCallback(async () => {
    try {
      if (onExportModel) {
        const data = await onExportModel();
        const headers = columns
          .filter((column) => column.exportField === true)
          .map(({ headerName }) => headerName);
        const rowsExcel = data.map((row) =>
          columns
            .filter((column) => column.exportField === true)
            .map(({
              field,
              render,
              isDate,
              isCurrency,
              isArea,
              isState,
              isStatus,
              isString,
              isRaw,
              customExcelRender,
            }) =>
              (render && render(row)) ?
                transfromRenderToString
                  (render(row),
                    isDate,
                    row,
                    field,
                    isCurrency,
                    isArea,
                    isState,
                    isStatus,
                    isString,
                    isRaw,
                    customExcelRender,
                  ) :
                row[field],
            ),
        );
        rowsExcel.unshift(headers);
        const table = dataToHTMLTable(rowsExcel);

        const newExportOptions = {
          table,
          worksheet: exportOptions?.worksheet || 'Reporte',
        };

        const content = template.replace(
          /{(\w+)}/g,
          (_, p) => newExportOptions[p as keyof typeof newExportOptions],
        );

        // const encoder = new TextEncoder();
        // const contentArray = encoder.encode(content);
        // const contentArrayAsArray = Array.from(contentArray);

        //La siguiente linea genera problemas con excel grandes
        //const base64 = window.btoa(String.fromCharCode.apply(null, contentArrayAsArray));

        //La siguiente línea funciona pero unescape está deprecado y genera problemas con tildes
        //const base64 = window.btoa(unescape(encodeURIComponent(content)));


        const base64 = Buffer.from(content, 'utf-8').toString('base64');


        const element = window.document.createElement('a');
        element.href = uri + base64;
        element.download = `${exportOptions?.filename || title}.xls`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    } catch (error) {
      console.log(error);
    }
  }, [exportOptions, title, columns, onExportModel]);

  const numberOfRecords = useMemo(
    () => paginationModel.page * paginationModel.rowsPerPage + 1,
    [paginationModel.page, paginationModel.rowsPerPage],
  );

  const totalPages = useMemo(
    () =>
      Math.ceil(
        paginationModel.rowsCount /
        (paginationModel.rowsPerPage ? paginationModel.rowsPerPage : 1),
      ),
    [paginationModel.rowsCount, paginationModel.rowsPerPage],
  );

  const numberOfRecordsDisplayed = useMemo(
    () => numberOfRecords - 1 + rows.length,
    [rows.length, numberOfRecords],
  );

  const handleSort = useCallback(
    (field: sortModelType<T>['field'], table?: sortModelType<T>['table']) =>
      (order?: sortModelType<T>['order']) => {
        const newSortModel = order
          ? {
            field,
            table,
            order,
          }
          : undefined;
        if (onSortModel) onSortModel(newSortModel);
      },
    [onSortModel],
  );

  return (
    <TableContainer $height={paginationModel.rowsPerPage}>
      <Toolbar>
        <PerfectScrollbar>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justify="space-between"
          >
            {title && (
              <Grid item>
                {typeof title === 'string' ? (
                  <Title variant="h2" wrap={false}>
                    {title}
                  </Title>
                ) : (
                  title
                )}
              </Grid>
            )}
            <Grid item xs>
              {filterComponent}
            </Grid>
          </Grid>
        </PerfectScrollbar>
      </Toolbar>
      <TableHead as="div">
        <TableRow as="div">
          {columns.map(
            (
              { headerName, field, table, flex, disabledSort, enableToggle, description, notDisplay },
              idx,
            ) => {

              const { toggled, onToggleChange } = toggleOptions?.[field] ?? { toggled: false, onToggleChange: () => { } };;

              return (
                !notDisplay &&
                <TableCell
                  as="div"
                  key={`head.${field}.${headerName}`}
                  $flex={flex}
                >
                  {headerName}
                  <Grid
                    container justify='space-between'
                    wrap="nowrap"
                  >
                    {rows.length > 0 && !disabledSort ? (
                      <SortTable
                        onSortModel={handleSort(field, table)}
                        sort={sortModel?.field === field ? sortModel.order : undefined}
                      />
                    ) : (
                      <Grid /> // Empty div when SortTable is not visible
                    )}
                    {rows.length > 0 && enableToggle && (
                      <Toggle
                        isChecked={toggled}
                        onToggleChange={onToggleChange}
                        sourceElement={field}
                        toggleSize='sm'
                      />
                    )}
                  </Grid>

                  {/* {description ? (
                  <Tooltip content={<>{description}</>}>
                    <div>{headerName}</div>
                  </Tooltip>
                ) : (
                  headerName
                )} */}
                  <Grow />
                  {columns.length - 1 > idx && (
                    <Svg
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="SeparatorIcon"
                    >
                      <path d="M11 19V5h2v14z"></path>
                    </Svg>
                  )}
                </TableCell>
              )
            },
          )}
        </TableRow>
      </TableHead>
      <PerfectScrollbar>
        <BasicTable<T>
          error={error}
          numberOfVisibleColumns={numberOfVisibleColumns}
          ref={ref}
          loading={loading}
          columns={columns}
          rows={rows}
          rowsPerPage={paginationModel.rowsPerPage}
        />
      </PerfectScrollbar>
      {rows.length > 0 && (
        <TableFooter>
          <PerfectScrollbar>
            <Grid container alignItems="center" spacing={4} wrap="nowrap">
              {exportOptions && (
                <Grid item>
                  {exportOptions.content || <IconButton
                    info={{ message: 'Descargar reporte', position: 'right' }}
                    color="grey"
                    onClick={onExportModel ? exportTable : undefined}
                    disabled={rows.length === 0 || loading}
                  >
                    <HiDownload size="19" />
                  </IconButton>}
                </Grid>
              )}
              <Grid item style={{ flexGrow: 1 }} />
              <Grid item>
                <Typography wrap={false}>Filas por página:</Typography>
              </Grid>
              <Grid item>
                <InputPagination
                  {...rowsPerPageProps}
                  fullWidth
                  type="number"
                  value={paginationModel.rowsPerPage}
                  disabled={loading}
                  inputProps={{
                    max: paginationModel.rowsCount,
                    min: 1,
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  wrap={false}
                >{`${numberOfRecords} - ${numberOfRecordsDisplayed} de ${paginationModel.rowsCount} resultados`}</Typography>
              </Grid>
              <Grid item>
                <IconButton
                  info="Primera página"
                  color="grey"
                  onClick={handlePage(0)}
                  disabled={loading || paginationModel.page === 0}
                >
                  <HiOutlineChevronDoubleLeft size="19" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  info="Página anterior"
                  color="grey"
                  onClick={handlePage(paginationModel.page - 1)}
                  disabled={loading || paginationModel.page === 0}
                >
                  <HiOutlineChevronLeft size="19" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  info="Siguiente página"
                  color="grey"
                  onClick={handlePage(paginationModel.page + 1)}
                  disabled={loading || paginationModel.page + 1 === totalPages}
                >
                  <HiOutlineChevronRight size="19" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  info="Última página"
                  color="grey"
                  onClick={handlePage(totalPages - 1)}
                  disabled={loading || paginationModel.page + 1 === totalPages}
                >
                  <HiOutlineChevronDoubleRight size="19" />
                </IconButton>
              </Grid>
            </Grid>
          </PerfectScrollbar>
        </TableFooter>
      )}
    </TableContainer>
  );
};

export default CustomTable;
