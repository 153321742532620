import { object, string, TypeOf } from 'yup';

export const ListPaymentsToApproveTemplateFilterSchema = object({
  projectId: string(),
  companyServiceId: string(),
  idReferenceRadication: string(),
  accountsPayable: string(),
});

export type ListPaymentsToApproveTemplateFilterType = TypeOf<
  typeof ListPaymentsToApproveTemplateFilterSchema
>;
