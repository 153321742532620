import { customerType } from "types";

type clientActions = {
    [customer in customerType]?: {
        [key: string]: {
            name_action: string;
            state: string;
            state_to_update: string;
            state_to_update_name: string;
        };
    } | undefined;
}

export const CLIENT_ACTIONS: clientActions = {
    bolivar_cali: {
        validar: {
            name_action: "Validar",
            state: "to_validate",
            state_to_update: "to_approve",
            state_to_update_name: "por aprobar",
        },
        aprobar: {
            name_action: "Aprobar",
            state: "to_approve",
            state_to_update: "approved",
            state_to_update_name: "aprobado",
        },
    }
};