import { DateRangePickerValue } from 'components/atoms/DateRangePicker/DateRangePicker';
import { isSameDay } from 'date-fns';
import moment from 'moment';
import { Range } from 'react-date-range';
export const isSelected = (range: Range) => {
  return (
    (range.startDate &&
      range.endDate &&
      isSameDay(range.startDate, range.startDate) &&
      isSameDay(range.endDate, range.endDate)) ||
    false
  );
};

export const dateRangeToString = (dateRange: DateRangePickerValue): string => {
  const formatDate = (date: string | number | Date): string => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = ('0' + (d.getMonth() + 1)).slice(-2);
    const day = ('0' + d.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const startDate = formatDate(dateRange.startDate);
  const endDate = formatDate(dateRange.endDate);
  return `${startDate}.${endDate}`;
};

export const formatDate = (
  date: string | Date,
  format = 'DD/MM/YYYY HH:mm:ss',
) => {
  return date ? moment(date).format(format) : '';
};