import { yupResolver } from '@hookform/resolvers/yup';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment';
import 'moment/locale/es';

import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Input,
  MenuItem,
  Select,
  SnackBar,
  Typography,
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { Table } from 'components/organisms';
import { DialogContent } from 'components/templates/AMTemplate/AMLogsTemplate/AMLogs.Styles';
import { GET_ALL_SERVERS } from 'const';
import { useDialog, useGetTable } from 'hooks';
import useTable, { filterModelType, tableModelType } from 'hooks/useTable';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IoAdd } from 'react-icons/io5';
import { ActionsInstanceResponse, PaginationType, Server } from 'types';
import ServerDetailTemplate from '../ServerDetailTemplate';
import { Providers, serverMonitoringConfig, StatusColor } from '../Shared/shared.const';
import {
  createFilterModel,
  defaultListServerTemplateFilter,
  defaultPagination,
  getColumns,
} from './ListServersTemplate.const';
import {
  ListServerTemplateFilterSchema,
  ListServerTemplateFilterType,
} from './ListServersTemplate.schema';
import { ListServerTemplateProps } from './ListServersTemplate.types';
const ListServersTemplate = ({ ...props }: ListServerTemplateProps) => {
  moment.locale('es');

  const [dataRows, setDataRows] = useState<Server[]>([]);
  const [selectedServerDialog, onOpen, onCloseModal] = useDialog<Server | undefined>(undefined);
  const [selectedServer, setSelectedServer] = useState<Server | undefined>(undefined);
  const [action, setAction] = useState<actionType>(undefined);
  const [serverActionMessage, setServerActionMessage] = useState<serverAction | undefined>(undefined);
  const [filterModel, setFilterModel] = useState<filterModelType<Server>[]>([]);
  const [loadingForm, setLoadingForm] = useState(false);
  let serversData: Server[] = [];

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ListServerTemplateFilterType>({
    defaultValues: defaultListServerTemplateFilter,
    resolver: yupResolver(ListServerTemplateFilterSchema),
  });

  type actionType = 'viewDetails' | 'stop' | 'start' | 'restart' | 'delete' | undefined;
  type serverAction = {
    message: string;
    severity: severityType;
  };

  const confirmProcess = useCallback(
    (server: Server, action: 'start' | 'stop' | 'restart' | 'delete') => {
      if (server !== undefined) {
        setSelectedServer(server);
        onOpen(server);
        setAction(action);
      }
    },
    [onOpen]
  );

  const dialogProps = (): { title: string; size: 'sm' | 'md' | 'lg' | 'xl' | undefined } => {
    return action === 'viewDetails'
      ? { title: 'Detalle del servidor', size: 'lg' }
      : { title: 'Confirmar acción', size: 'sm' };
  };


  const onViewDetails = useCallback(
    (server: Server) => {
      setSelectedServer(server);
      onOpen(server);
      setAction('viewDetails');
    },
    [onOpen]
  );

  const onClose = useCallback(() => onCloseModal(), [onCloseModal]);
  const columns = useMemo(() => getColumns(onViewDetails, confirmProcess), [onViewDetails, confirmProcess]);

  const fetchServers = async (
    { paginationModel, filterModel }: tableModelType<Server>,
    config?: AxiosRequestConfig<Server>,
  ) => {
    return new Promise<AxiosResponse<PaginationType<Server>>>(
      async (resolve) => {
        const data = await axios.get<PaginationType<Server>>(
          `${GET_ALL_SERVERS}?page=0&limit=1000`, {
          ...config,
          ...serverMonitoringConfig,
        },
        );

        if (data.status === 200) {
          serversData = data.data.result;
        }

        if (filterModel && filterModel.length > 0) {
          serversData = serversData.filter((server) =>
            filterModel.every((filter) => {
              const { field, value } = filter;
              if (field === 'ip') return server.ip.toLowerCase().includes(value.toLowerCase());
              if (field === 'status') return value.toLowerCase().split(',').includes(server.status.toLowerCase());
              if (field === 'name') return server.name.toLowerCase().includes(value.toLowerCase());
              if (field === 'provider') return value.includes(server.provider);
              return false;
            })
          )
        }


        let rows = [...serversData];
        const { rowsPerPage, page } = paginationModel;
        paginationModel.page = page > rows.length / rowsPerPage ? 0 : page;
        rows = rows.slice(paginationModel.page * rowsPerPage, (paginationModel.page + 1) * rowsPerPage);

        setDataRows(rows);

        const responseData: AxiosResponse<PaginationType<Server>, any> = {
          status: data.status,
          statusText: data.statusText,
          headers: data.headers,
          config: data.config,
          data: {
            page: 0,
            limit: data.data.limit,
            rowsCount: serversData.length,
            result: [...serversData],
          },
        };

        resolve(responseData);
      },
    );
  };

  const { loading, error, data: server, handleData, getData } = useGetTable<PaginationType<Server>, tableModelType<Server>>(
    fetchServers,
    { paginationModel: defaultPagination, filterModel }
  );

  const tableControllers = useTable<Server>(
    {
      filterModel,
      paginationModel: server ? { ...defaultPagination, rowsCount: server.rowsCount } : defaultPagination,
    },
    handleData,
    getData as (param: Omit<tableModelType<Server>, 'paginationModel'>) => Promise<PaginationType<Server>>
  );

  const onSubmit = useCallback(
    (filterListServersTemplate: ListServerTemplateFilterType) => {
      const { name, status, ip, provider } = filterListServersTemplate;
      const filterServer = createFilterModel({ name, status, ip, provider });
      setFilterModel(filterServer.filter((item) => item) as filterModelType<Server>[]);
    },
    []
  );

  const executeServerAction = useCallback(
    async (server?: Server, action?: actionType) => {
      if (!server || !action) return;

      const handleError = (error: AxiosError) => {
        setServerActionMessage({
          message: `Ocurrió un error, ${error?.response?.data?.['error'] ?? error.message ?? 'intente nuevamente'}`,
          severity: 'error',
        });
      };

      try {
        if (action === 'delete') {
          await axios.delete(`${GET_ALL_SERVERS}/${server.id}`, serverMonitoringConfig);
          setServerActionMessage({ message: 'Servidor eliminado correctamente', severity: 'success' });
        } else {
          const { provider, instanceId } = server;
          const response = await axios.post<ActionsInstanceResponse>(
            `${GET_ALL_SERVERS}/instance/${provider}/${instanceId}/${action}`,
            {},
            serverMonitoringConfig
          );
          setServerActionMessage({
            message: response.data.message,
            severity: response.data.success ? 'success' : 'error',
          });
        }
      } catch (error) {
        handleError(error as AxiosError);
      } finally {
        getData({ filterModel, paginationModel: defaultPagination });
        onClose();
      }
    },
    [getData, onClose, filterModel]
  );


  const colors = Object.keys(StatusColor);
  type FormFields = 'name' | 'ip' | 'status' | 'provider';

  return (
    <>
      <Table<Server>
        {...tableControllers}
        numberOfVisibleColumns={4}
        title={`Encontramos ${tableControllers.paginationModel.rowsCount} servidores.`}
        columns={columns}
        rows={dataRows}
        error={!!error}
        loading={loading}
        filterComponent={
          <form noValidate onSubmit={handleSubmit(onSubmit)} onKeyDown={
            (event) => {
              // submit form on enter key and not go to create service page
              if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
                handleSubmit(onSubmit)();
              }
            }
          }>
            <Grid container alignItems="center" justify="flex-end" spacing={2} wrap="nowrap">
              <Grid item>
                <IconButton
                  info="Crear servidor"
                  variant="contained"
                  href="/pages/monitoring/servers/create"
                >
                  <IoAdd />
                </IconButton>
              </Grid>
              {[
                { label: 'Nombre', name: 'name', error: errors.name?.message },
                { label: 'IP', name: 'ip', error: errors.ip?.message },
              ].map((input, index) => (
                <Grid item xs={2} key={index}>
                  <Input
                    fullWidth
                    label={input.label}
                    {...register(input.name as FormFields)}
                    error={!!errors[input.name as FormFields]}
                    helperText={input.error}
                  />
                </Grid>
              ))}

              {[
                { name: 'status', label: 'Estado', items: colors },
                { name: 'provider', label: 'Proveedor', items: Providers },
              ].map((select, index) => (
                <Grid item xs={2} key={index}>
                  <Controller
                    name={select.name as FormFields}
                    control={control}
                    render={({ field }) => (
                      <Select
                        value={field.value}
                        onChange={field.onChange}
                        label={select.label}
                        fullWidth
                        multiple
                        enableFilter
                      >
                        {select.items?.map((item) => (
                          <MenuItem key={item} label={`${item}`} value={`${item}`}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
              ))}

              <Grid item>
                <Button type="submit" variant="contained" color="primary">
                  Filtrar
                </Button>
              </Grid>
            </Grid>
          </form>
        }
      />
      <Dialog
        open={!!selectedServerDialog}
        onClose={onClose}
        title={dialogProps().title}
        maxWidth={dialogProps().size}
      >
        <>
          {action === 'viewDetails' && (
            <DialogContent>
              <ServerDetailTemplate server={selectedServer} />
            </DialogContent>
          )}

          <Grid container spacing={5} justify="flex-end">
            <Grid item xs={12}>
              {['start', 'stop', 'restart', 'delete'].includes(action ?? '') && (
                <Typography variant="h2">
                  {`¿Estás seguro de ${action === 'stop' ? 'detener' : action === 'start' ? 'iniciar' : action === 'restart' ? 'reiniciarlo' : 'eliminarlo'} el servidor: ${selectedServer?.name}?`}
                </Typography>
              )}
            </Grid>
            {['start', 'stop', 'restart', 'delete'].includes(action ?? '') && (
              <>
                <Grid item>
                  <Button variant="contained" color="grey" onClick={onClose}>
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      executeServerAction(selectedServer, action);
                    }}
                    loading={loadingForm}
                  >
                    Sí, deseo {action === 'stop' ? 'detenerlo' : action === 'start' ? 'iniciarlo' : action === 'restart' ? 'reiniciarlo' : 'eliminarlo'}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </>
      </Dialog>
      {
        serverActionMessage && (
          <SnackBar
            wait={2000}
            open={serverActionMessage !== undefined}
            onClose={() => {
              setServerActionMessage(undefined)
              onClose();
            }}
            severity={serverActionMessage.severity}
          >
            {serverActionMessage.message}
          </SnackBar>
        )
      }
    </>
  );

};

export default ListServersTemplate;
