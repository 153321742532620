import { Button, Grid, Typography } from 'components/atoms';
import { useState } from 'react';
import { Spinner } from "../../../../atoms";
import { CLIENT_ACTIONS } from '../HistoryPendingTemplate/ClientActions.const';
import { updateHistories } from './HistoryConfirmationClientActionTemplate.const';
import { HistoryConfirmationClientActionTemplateProps } from './HistoryConfirmationClientActionTemplate.types';

const HistoryConfirmationClientActionTemplate = ({
  setOpen,
  onClose,
  setErrorMessage,
  listHistory,
  setModifiedHistory,
  setActionSnackBar,
  filterModel,
  setFilterModel,
  isFiltering,
  currentFilterModel,
  action,
  customer,
  ...props
}: HistoryConfirmationClientActionTemplateProps) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const onBatchUpdate = async () => {
    setIsUpdating(true);
    await updateHistories({
      listHistory,
      setModifiedHistory,
      setOpen,
      setErrorMessage,
      setActionSnackBar,
      setIsUpdating,
      action,
      customer,
    });
    setIsUpdating(false);
    setOpen('success');
    setActionSnackBar('activate');
  };

  const onUpdate = async () => {
    await onBatchUpdate();
    setFilterModel([...currentFilterModel]);
    onClose();
  };

  const onDiscard = () => {
    try {
      setModifiedHistory([]);
      setFilterModel([...currentFilterModel]);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const getConfirmationText = () => {
    let isFilteringMessage = isFiltering ? 'Antes de filtrar los inmuebles ' : '';

    const clientAction = CLIENT_ACTIONS?.[customer]?.[action];

    if (listHistory.length === 1) {
      return `${isFilteringMessage}¿Desea cambiar el estado de la factura del inmueble ${listHistory[0].groupingName} a '${clientAction?.state_to_update_name}'?`;
    } else {
      return `${isFilteringMessage}¿Deseas cambiar el estado de las facturas seleccionadas a '${clientAction?.state_to_update_name}'?`;
    }
  };

  return (
    <>
      {!isUpdating ?
        <Grid container spacing={3}>
          <Grid item>
            <Typography variant='h2'>
              {getConfirmationText()}
            </Typography>

          </Grid>
          <Grid container spacing={3} justify="flex-end"
            alignItems='center'>
            <Grid item>
              <Button
                info="Actualizar inmuebles"
                color="primary"
                variant="contained"
                onClick={() => onUpdate()}
              >
                Aceptar
              </Button>
            </Grid>
            <Grid item>
              <Button
                info="Descartar cambios"
                color="primary"
                variant="contained"
                onClick={() => onDiscard()}
              >
                Descartar cambios
              </Button>
            </Grid>
          </Grid>
        </Grid>
        : <Spinner></Spinner>
      }
    </>
  );
};

export default HistoryConfirmationClientActionTemplate;
