import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Input,
  SnackBar,
  Typography
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { Table } from 'components/organisms';
import { useDialog, useDownloadFile, useGetPermissions, useGetTable } from 'hooks';
import useTable, { filterModelType, tableModelType } from 'hooks/useTable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineCloudDownload, AiOutlineUpload } from 'react-icons/ai';
import { IoAdd } from 'react-icons/io5';
import { PaginationType, Project } from 'types';
import { createFilterStructure } from 'utils';
import ProjectUploadTemplate from '../ProjectUploadTemplate';
import {
  defaultListProjectTemplateFilter,
  defaultPagination,
  defaultSortModel,
  downloadExcelReport,
  downloadTemplate,
  fetchProcess,
  getColumns,
  updateStatus
} from './ListProjectTemplate.const';
import ListProjecteApiLoading from './ListProjectTemplate.loading';
import {
  ListProjectTemplateFilterSchema,
  ListProjectTemplateFilterType
} from './ListProjectTemplate.schema';
import { ListProjectTemplateProps } from './ListProjectTemplate.types';

const ListProjectTemplate = ({ ...props }: ListProjectTemplateProps) => {
  const [loadingForm, setLoadingForm] = useState(false);
  // const [costCenter, setcostCenter] = useState<0 | 1>(0);
  // const [constructionSite, setConstructionSite] = useState<0 | 1>(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [textAlertError, setTextAlertError] = useState('Ocurrió un error, intenta nuevamente');
  const [selected, onOpen, onCloseModal] = useDialog<number | undefined>(
    undefined,
  );
  const [action, setAction] = useState<'remove' | 'edit' | 'upload' | 'download' | undefined>(
    undefined,
  );
  const [open, setOpen] = useState<severityType>();
  const [actionSnackBar, setActionSnackBar] = useState<'remove' | 'edit' | 'upload' | 'download' | undefined>(
    undefined,
  );
  const [actionSnackBarMessage, setActionSnackBarMessage] = useState<string | undefined>(undefined);
  const [isUpdatingFile, setIsUpdatingFile] = useState(false);
  const [filterModel, setFilterModel] = useState<filterModelType<Project>[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [downloadType, setDownloadType] = useState<'list' | 'template' | undefined>(undefined);
  // useEffect(() => {
  //   const loadCostCenter = async () => {
  //     const response = await fetchParameterCostCenter();
  //     console.log('fetchParameterCostCenter', response.data);
  //     setcostCenter(response.data); // Asegúrate de que response.data sea 0 o 1
  //   };

  //   const loadConstructionSite = async () => {
  //     const response = await fetchParameterConstructionSite();
  //     console.log('fetchParameterConstructionSite', response.data);
  //     setConstructionSite(response.data); // Asegúrate de que response.data sea 0 o 1
  //   };

  //   loadCostCenter();
  //   loadConstructionSite();
  // }, []);

  const { canEdit, canInsert } = useGetPermissions();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ListProjectTemplateFilterType>({
    defaultValues: defaultListProjectTemplateFilter,
    resolver: yupResolver(ListProjectTemplateFilterSchema),
  });



  const confirmEdit = useCallback(
    (id: number) => {
      onOpen(id);
      setAction('edit');
    },
    [onOpen],
  );

  const onCloseSnackBar = useCallback(() => {
    setActionSnackBar(undefined);
    setOpen(undefined);
  }, []);

  const onClose = useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);

  const columns = useMemo(
    () => getColumns(confirmEdit, canEdit),
    [confirmEdit, canEdit],
  );



  const {
    loading,
    error,
    handleData,
    getData,
    data: process,
  } = useGetTable<PaginationType<Project>, tableModelType<Project>>(fetchProcess, {
    paginationModel: defaultPagination,
    sortModel: defaultSortModel,
    filterModel,
  });

  const tableControllers = useTable<Project>(
    {
      filterModel,
      sortModel: defaultSortModel,
      paginationModel: process
        ? { ...defaultPagination, rowsCount: process.rowsCount }
        : defaultPagination,
    },
    handleData,
    getData as (
      param: Omit<tableModelType<Project>, 'paginationModel'>,
    ) => Promise<PaginationType<Project>>,
  );

  const editProject = async (idProject?: number) => {
    setWaitingResponse(true);
    if (idProject) {
      try {
        await updateStatus(idProject);
        setOpen("success");
        //Sorting to refresh
        tableControllers.onSortModel(defaultSortModel);
      }
      catch (error) {
        setOpen("error");
      } finally {
        setActionSnackBar("edit");
        setWaitingResponse(false);
        onClose();
      }
    }
  };

  const preDownloading = () => {
    setIsDownloading(true);
  };

  const postDownloading = () => {
    setIsDownloading(false);
  };

  const onErrorDownloadFile = () => {
    setIsDownloading(false);
    setOpen('error');
    setTextAlertError('Ocurrio un error al intentar descarga')
  };

  const getFileName = () => {
    return downloadType === 'list' ? 'report-proyectos.xlsx' : 'plantilla-migrar-proyectos-desde-sinco.xlsx';
  };

  const { download } = useDownloadFile({
    apiDefinition: downloadType === 'list' ? downloadExcelReport : downloadTemplate,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName,
  });

  const onUpload = useCallback(
    () => {
      onOpen(0);
      setAction('upload');
      setActionSnackBar('upload')
    },
    [onOpen],
  );

  const onDownload = useCallback(async () => {
    try {
      await download();
    } catch (error) {
      console.log(error);
    }
  }, [download]);

  useEffect(() => {
    if (downloadType !== undefined) {
      onCloseModal();
      onDownload();
      setAction(undefined);
      setDownloadType(undefined);
    }
  }, [downloadType, onDownload, onOpen]);

  const dialogTitle = (): string => {
    switch (action) {
      case 'edit':
      case 'remove':
        return "Confirma esta acción"
      case 'upload':
        return "Carga excel para actualizar proyectos";
      case 'download':
        return "Descargar archivo";
      default:
        return '';
    }
  }

  const onSubmit = useCallback(
    (filterListProjectTemplate: ListProjectTemplateFilterType) => {
      const filterProjectName = createFilterStructure(
        'name',
        'contains',
        filterListProjectTemplate.name,
      );

      tableControllers.paginationModel.page = 0;
      const modelFilter = [filterProjectName].filter((item) => item);
      setFilterModel(modelFilter as filterModelType<Project>[]);
    },
    [tableControllers],
  );

  const selectedProject = useMemo(
    () =>
      selected !== undefined
        ? process?.result.find((project) => project.id === selected)
        : undefined,
    [selected, process],
  );

  return (
    <>
      <Table<Project>
        {...tableControllers}
        numberOfVisibleColumns={4}
/*         title={`Encontramos ${tableControllers.paginationModel.rowsCount} proyectos`}
 */        columns={columns}
        rows={process?.result || []}
        error={!!error}
        loading={loading}
        exportOptions={{
          filename: 'Proyectos',
        }}
        filterComponent={
          <Grid
            container
            alignItems="center"
            justify="flex-end"
            spacing={4}
            wrap="nowrap"
          >
            {/* canInsert() */ true && <Grid item>
              <IconButton
                variant="contained"
                href="/pages/servicios-publicos/management/project/create"
                info="Crear proyecto"
              >
                <IoAdd />
              </IconButton>
            </Grid>}
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  justify="flex-end"
                  spacing={4}
                  wrap="nowrap"
                >
                  <Grid item xs={10}>
                    <Input
                      fullWidth
                      placeholder="Busca por nombre"
                      {...register('name')}
                      error={!!errors.name}
                      helperText={errors.name && errors.name.message}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      isSmall
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Filtrar
                    </Button>
                  </Grid>
                  <Grid item>
                    {/* (canInsert() && canEdit()) */ true && <Grid item>
                      <IconButton
                        info="Cargar información masiva del proyecto"
                        color="primary"
                        loading={isUpdatingFile}
                        onClick={() => onUpload()}
                      >
                        <AiOutlineUpload />
                      </IconButton>
                    </Grid>}
                    <Grid item>
                      <IconButton
                        info="Descarga listado de proyectos"
                        color="primary"
                        loading={isDownloading}
                        onClick={() => {
                          setAction('download')
                          onOpen(1)
                        }}
                      >
                        <AiOutlineCloudDownload />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        }
      />
      <Dialog
        open={selected !== undefined}
        onClose={onClose}
        title={dialogTitle()}
      >
        {!waitingResponse ?
          <Grid container spacing={3} justify="flex-end">
            {action === 'remove' && (
              <Grid item xs={12}>
                <Typography variant="h2">
                  {`¿Estas seguro de eliminar el proyecto ${selectedProject?.name}?`}
                </Typography>
              </Grid >
            )}
            {action === 'edit' && (
              <Grid item xs={12}>
                <Typography variant="h2">
                  {`¿Estas seguro de editar el estado del proyecto ${selectedProject?.name}?`}
                </Typography>
              </Grid>
            )}
            {['edit', 'remove'].includes(action ? action : '') && (<Grid item>
              <Button variant="contained" color="grey" onClick={onClose}>
                Cancelar
              </Button>
            </Grid>)}
            {action === 'edit' && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    editProject(selectedProject?.id)
                  }
                  loading={loadingForm}
                >
                  Si, deseo modificarlo
                </Button>
              </Grid>
            )}
            {action === 'upload' && (
              <Grid item xs={12}>
                <ProjectUploadTemplate
                  waitingResponse={waitingResponse}
                  setWaitingResponse={setWaitingResponse}
                  action={action}
                  setAction={setAction}
                  open={open}
                  setOpen={setOpen}
                  onClose={onClose}
                  onOpen={onOpen}
                  setErrorMessage={setErrorMessage}
                  setActionSnackBarMessage={setActionSnackBarMessage}
                />
              </Grid>
            )}
            {action === 'download' && (
              <Grid xs={12} container spacing={1} justify="center">
                <Grid item>
                  <Button
                    info="Descargar plantilla"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setDownloadType('template');
                    }}
                    style={{ fontSize: '16px' }}
                  >
                    Descargar plantilla migración Sinco
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    info="Descargar listado de proyectos"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setDownloadType('list');
                    }}
                    style={{ fontSize: '16px' }}
                  >
                    Descargar listado de proyectos
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid> : <ListProjecteApiLoading />}
      </Dialog>
      <SnackBar
        wait={6000}
        open={open !== undefined}
        onClose={onCloseSnackBar}
        severity={open}
      >
        {open === 'success' &&
          actionSnackBar === 'edit' &&
          'Se editó el estado del proyecto exitosamente'}
        {open === 'success' &&
          actionSnackBar === 'remove' &&
          'Eliminamos el proyecto exitosamente'}
        {open === 'success' &&
          actionSnackBar === 'upload' &&
          (actionSnackBarMessage || 'El archivo se cargó correctamente')}
        {open === 'warning' && errorMessage}
        {open === 'error' && textAlertError}
      </SnackBar>
    </>
  );
};

export default ListProjectTemplate;
