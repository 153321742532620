import { withAuth } from 'HOCs';
import { Header } from 'components/molecules';
import ListPaymentsToApproveTemplate from 'components/templates/PublicServicesTemplate/ApprovePaymentsTemplate/ListPaymentsToApproveTemplate';


const breadcrumbs = [{ label: 'Servicios públicos' }];

const ListPaymentsToApprovePage = () => {
  return (
    <>
      <Header
        title="Pagos por aprobar"
        subtitle="Listado de pagos por aprobar"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <ListPaymentsToApproveTemplate />
    </>
  );
}

export default withAuth(ListPaymentsToApprovePage);