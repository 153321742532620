import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Grid,
  IconButton,
  Input, MenuItem, Paper, Select, SnackBar
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { GET_COMPANIES } from 'const';
import { useGetTable } from 'hooks';
import useGetData from 'hooks/useGetData';
import { useEffect, useState } from 'react';
import { Controller, ControllerRenderProps, useFieldArray, useForm } from 'react-hook-form';
import { AiOutlineDelete } from 'react-icons/ai';
import { IoAdd } from 'react-icons/io5';
import { useNavigate, useParams } from 'react-router-dom';
import { Company, Grouping } from 'types';
import { getErrorsFromBackend } from 'utils';
import { areas, httpErrorMessageFormatter, states } from '../Shared/sharedConsts';
import { defaultData, fetchGrouping, updateGrouping } from './EditGroupingTemplate.const';
import EditGroupingTemplateLoading from './EditGroupingTemplate.loading';
import { CompanyServiceGroupingField, EditGroupingSchema, EditGroupingType } from './EditGroupingnTemplate.schema';


/**
 * Se pausó el desarrollo de este componente pues el cliente no lo requirió,
 * si posteriormente lo requiere se puede retomar, tiene varias cosas implementadas,
 * hace falta principalmente generar endpoint del backend para actualizar los
 * inmuebles con sus respectivos companyServiceGroupings
 */

const EditGroupingTemplate = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState<severityType>();
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedCompanyCodes, setSelectedCompanyCodes] = useState<string[]>([]);

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    register,
    watch,
    formState: { errors },
  } = useForm<EditGroupingType>({
    resolver: yupResolver(EditGroupingSchema),
    defaultValues: {
      name: '',
      projectName: '',
      area: 'UNIDAD',
      status: '',
      companyServiceGroupings: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'companyServiceGroupings',
    control
  });

  const { name, projectName, area, status, companyServiceGroupings } = watch();

  const { data, loading: loadingGrouping } = useGetTable<Grouping, { id: string }>(
    fetchGrouping,
    { id: id as string },
  );

  const { data: companiesFetch, loading: loadingCompanies } = useGetData<Company>(
    GET_COMPANIES
  );

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/servicios-publicos/inmueble');
  };

  const isDisabled = () => {
    if (!name || !projectName || !area || !status) return true;
    if (companyServiceGroupings && companyServiceGroupings.length > 0) {
      console.log('entre');
      console.log(companyServiceGroupings);
      console.log(companyServiceGroupings.some((companyServiceGrouping) => {
        return !companyServiceGrouping.companyServiceCode || !companyServiceGrouping.reference;
      }));

      return companyServiceGroupings.some((companyServiceGrouping) => {
        return !companyServiceGrouping.companyServiceCode || !companyServiceGrouping.reference;
      })
    };
    return false;
  };

  const onAppend = () => {
    companyServiceGroupings?.forEach(csg => {
      if (csg.companyServiceCode && !selectedCompanyCodes.includes(csg.companyServiceCode)) {
        setSelectedCompanyCodes(prevCodes => [...prevCodes, csg.companyServiceCode as string]);
      }
    })
    append({
      companyServiceGroupingId: 0,
      reference: "",
      companyServiceCode: ""
    })
  }

  //Function to watch the select changes in the companyServiceCode field and update the selectedCompanyCodes state so the user can't select the same company twice
  const handleCompanyServiceCodeChange = (
    event: React.FormEvent<HTMLDivElement> | React.SyntheticEvent<Element, Event>,
    fieldToRender: ControllerRenderProps<EditGroupingType, CompanyServiceGroupingField>
  ) => {
    fieldToRender.onChange(event);

    if ((event.target as HTMLInputElement).value && companyServiceGroupings?.length) {

      const companyServiceCodes = companyServiceGroupings
        .map(
          csg => csg.companyServiceCode)
        .filter(
          (cs): cs is string => !!cs
        );

      const uniqueCompanyServiceCodes = companyServiceCodes.filter(
        (code, index) => companyServiceCodes.indexOf(code) === index
      );

      if (uniqueCompanyServiceCodes.length) {
        setSelectedCompanyCodes(uniqueCompanyServiceCodes);
      }
    }
  };


  const onSubmit = async (groupingForm: EditGroupingType) => {
    console.log(groupingForm);
    try {

      setLoading(true);
      await updateGrouping(id, groupingForm);
      setLoading(false);
      setOpen('success');
    } catch (error) {
      console.log(error);
      setErrorMessage((error as Error).message);
      setOpen('error');
      setLoading(false);
      const { isGeneral, errorOptions } =
        getErrorsFromBackend<EditGroupingType>(error);

      if (isGeneral) {
        setErrorMessage((error as Error).message);
        setError(...errorOptions);
        setOpen('error');
      } else
        errorOptions.forEach((error) => {
          setError(...error);
        });
    }
  };

  useEffect(() => {
    if (data && !loadingGrouping) {
      setValue('projectName', data.project_name);
      setValue('name', data.name);
      setValue('area', data.area);
      setValue('status', data.status);
      if (data.companyServiceGrouping) {
        data.companyServiceGrouping.forEach(csg => {
          append({
            companyServiceGroupingId: csg.companyServiceGroupingId || null,
            reference: csg.companyServiceGroupingReference || '',
            companyServiceCode: csg.companyServiceCode || ''
          })

          const companyServiceCodes = data.companyServiceGrouping.map(csg => csg.companyServiceCode);

          const uniqueCompanyServiceCodes = companyServiceCodes.filter((code, index) => companyServiceCodes.indexOf(code) === index);

          setSelectedCompanyCodes(uniqueCompanyServiceCodes);
        })
      }

    }
  }, [data, setValue]);

  useEffect(() => {
    if (companyServiceGroupings?.length) {
      companyServiceGroupings.forEach(csg => {
        if (csg.companyServiceCode && !selectedCompanyCodes.includes(csg.companyServiceCode)) {
          setSelectedCompanyCodes(prevCodes => [...prevCodes, csg.companyServiceCode as string]);
        }
      })
    }
  },
    [companyServiceGroupings]);


  return (
    <Paper>
      {!loadingGrouping || !loadingCompanies ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={3}
            justify="flex-start"
            alignItems='center'
          >
            <Grid item xs={4}>
              <Input
                label="Proyecto"
                fullWidth
                defaultValue={defaultData.projectName}
                disabled
                {...register('projectName')}
                error={!!errors.projectName}
                helperText={errors.projectName?.message}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                label="Inmueble"
                fullWidth
                defaultValue={defaultData.name}
                disabled
                {...register('name')}
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                name="area"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      required
                      value={field.value}
                      onChange={field.onChange}
                      label="Área"
                      fullWidth
                      enableFilter
                    >
                      {areas.map(({ value, name }) => (
                        <MenuItem
                          key={name}
                          label={`${name}`}
                          value={`${value}`}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                name="status"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      required
                      value={field.value}
                      onChange={field.onChange}
                      label="Estado"
                      fullWidth
                      enableFilter
                    >
                      {states.map(({ value, name }) => (
                        <MenuItem
                          key={name}
                          label={`${name}`}
                          value={`${value}`}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={1} >
              <IconButton
                type='button'
                positionEdge="start"
                edge={5}
                variant="contained"
                onClick={onAppend}
                info="Añadir servicios"
              >
                <IoAdd />
              </IconButton>
            </Grid>
          </Grid>
          {fields.map((field, index) => (
            <Grid container spacing={3} justify="flex-start" key={field.id} alignItems='center'>
              <Grid item xs={5}>
                <Controller
                  name={`companyServiceGroupings.${index}.companyServiceCode`}
                  control={control}
                  render={({ field: fieldToRender }) => {
                    return (
                      <Select
                        value={fieldToRender.value ?? ''}
                        onChange={(event) => handleCompanyServiceCodeChange(event, fieldToRender)}
                        label="Compañía"
                        fullWidth
                        disabled={!!field.companyServiceGroupingId}
                        required
                        enableFilter
                      >
                        {companiesFetch ?
                          companiesFetch
                            .filter(company =>
                              !selectedCompanyCodes.includes(company.code) || company.code === fieldToRender.value
                            )
                            .sort((comp1, comp2) => (comp1.name > comp2.name) ? 1 : -1)
                            .map(({ id, name, code }) => (
                              <MenuItem
                                key={code}
                                label={`${name}`}
                                value={`${code}`}
                              >
                                {name}
                              </MenuItem>
                            )) : <></>}
                      </Select>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  label="Referencia"
                  fullWidth
                  required
                  defaultValue={"-"}
                  {...register(`companyServiceGroupings.${index}.reference`)}
                />
              </Grid>
              {!field.companyServiceGroupingId && <Grid item xs={1}>
                <IconButton
                  type='button'
                  info="Eliminar servicio"
                  positionEdge="start"
                  edge={5}
                  color="error"
                  onClick={() => remove(index)}
                >
                  <AiOutlineDelete />
                </IconButton>
              </Grid>}
            </Grid>
          ))}
          <Grid container spacing={3} justify="flex-end">
            <Grid item>
              <Button
                fullWidth
                variant="text"
                color="grey"
                href="/pages/servicios-publicos/inmueble"
                type="button"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
                disabled={isDisabled()}
              >
                Actualizar
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            wait={3000}
            open={open !== undefined}
            onClose={onClose}
            severity={open}
          >
            {open === 'success' && 'Se editó el inmueble con éxito'}
            {open === 'error' && (errorMessage ? httpErrorMessageFormatter(errorMessage) : 'Ocurrió un error, intenta nuevamente')}
          </SnackBar>
        </form>
      ) : (
        <EditGroupingTemplateLoading />
      )}
    </Paper>
  );
};

export default EditGroupingTemplate;
