import { yupResolver } from "@hookform/resolvers/yup";
import axios, { AxiosRequestConfig } from "axios";
import {
    Button,
    Dialog,
    Grid,
    Input,
    MenuItem,
    Select,
    SnackBar
} from 'components/atoms';
import { severityType } from "components/atoms/Alert/Alert.styles";
import { Table } from 'components/organisms';
import { GET_COMPANIES, GET_PAYMENTS_TO_APPROVE, GET_PROJECTS } from "const";
import { useDialog, useGetTable } from "hooks";
import useGetData from "hooks/useGetData";
import useTable, { filterModelType, tableModelType } from "hooks/useTable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Company, PaginationType, PaymentsToApprove, Project } from "types";
import { createPayload } from "utils";
import PaymentsToApproveConfirmationTemplate from "../PaymentsToApproveConfirmationTemplate";
import { createFilterModel, defaultFilter, defaultPagination, defaultStatusFilter, getColumns, updatePayments } from "./ListPaymentsToApproveTemplate.const";
import { ListPaymentsToApproveTemplateFilterSchema, ListPaymentsToApproveTemplateFilterType } from "./ListPaymentsToApproveTemplate.schema";

const ListPaymentsToApproveTemplate = () => {

    const { data: projectsFetch } = useGetData<Project>(GET_PROJECTS);
    const { data: companiesFetch } = useGetData<Company>(GET_COMPANIES);

    const [selectedPayments, setSelectedPayments] = useState<PaymentsToApprove[]>([]);
    const [filterModel, setFilterModel] = useState<filterModelType<PaymentsToApprove>[]>(defaultStatusFilter);
    const [value, setValue] = useState<number>(0);
    const [statusToggle, setStatusToggle] = useState<boolean>(false);
    const [action, setAction] = useState<'confirm' | undefined>(undefined);
    const [actionSnackBar, setActionSnackBar] = useState<'confirm' | 'activate' | undefined>(undefined);
    const [open, setOpen] = useState<severityType>();
    const [selected, onOpen, onCloseModal] = useDialog<number | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isFiltering, setIsFiltering] = useState<boolean>(false)

    const onCloseSnackBar = useCallback(() => {
        setActionSnackBar(undefined);
        setOpen(undefined);
    }, []);

    const fetchPaymentsToApprove = async (
        tableModel: tableModelType<PaymentsToApprove>,
        config?: AxiosRequestConfig<PaymentsToApprove>,
    ) => {
        return await axios.get<PaginationType<PaymentsToApprove>>(
            `${GET_PAYMENTS_TO_APPROVE}${createPayload(tableModel)}`, {
            ...config,
        });
    };

    const { loading, error, data: payments, handleData, getData } = useGetTable<PaginationType<PaymentsToApprove>, tableModelType<PaymentsToApprove>>(
        fetchPaymentsToApprove,
        { paginationModel: defaultPagination, filterModel },
    );

    const tableControllers = useTable<PaymentsToApprove>(
        {
            filterModel,
            paginationModel: payments ? { ...defaultPagination, rowsCount: payments.rowsCount } : defaultPagination,
        },
        handleData,
        getData as (param: Omit<tableModelType<PaymentsToApprove>, 'paginationModel'>) => Promise<PaginationType<PaymentsToApprove>>
    );

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<ListPaymentsToApproveTemplateFilterType>({
        defaultValues: defaultFilter,
        resolver: yupResolver(ListPaymentsToApproveTemplateFilterSchema),
    });

    const onCheck = useCallback((newState: boolean, sourceElement: number | string | number[] | string[] | undefined): void => {
        const checked = newState;
        const pays = payments?.result.find((payments) => payments.id === sourceElement);

        if (!checked) {
            const newSelectedPayments = selectedPayments.filter((history) => history.id !== sourceElement);
            setSelectedPayments(newSelectedPayments);
            return;
        }

        if (pays) {
            updatePayments(setSelectedPayments, [pays]);
        }
    }, [payments, selectedPayments]);

    const onMultipleChecks = useCallback((newState: boolean, sourceElement: number | string | number[] | string[] | undefined): void => {
        setStatusToggle(newState);
        if (!newState) {
            setSelectedPayments([]);
            return;
        }
        updatePayments(setSelectedPayments, payments?.result ?? []);
    }, [payments?.result]);


    const handleStatusToggle = (
        newState: boolean,
        sourceElement: string | number | number[] | string[] | undefined
    ) => {
        if (sourceElement === 'selected') {
            onMultipleChecks(newState, sourceElement);
        }
    }

    const getToggleOptions = () => {
        return {
            selected: {
                toggled: statusToggle,
                onToggleChange: handleStatusToggle,
            }
        }
    }

    const onSubmit = useCallback((filterListPaymentsToApproveTemplate: ListPaymentsToApproveTemplateFilterType) => {

        const { accountsPayable, projectId, companyServiceId, idReferenceRadication } = filterListPaymentsToApproveTemplate;

        const filterPaymentsToApprove = createFilterModel({ accountsPayable, projectId, companyServiceId, idReferenceRadication });

        setFilterModel([...defaultStatusFilter, ...filterPaymentsToApprove.filter((item) => item) as filterModelType<PaymentsToApprove>[]]);
    }, []);

    const columns = useMemo(() => getColumns(selectedPayments, onCheck), [selectedPayments, onCheck]);

    const dialogProps = (): { title: string; size: 'sm' | 'md' | 'lg' | 'xl' | undefined } => {
        return action === 'confirm'
            ? { title: 'Confirmar pagos', size: 'md' }
            : { title: 'Detalles', size: 'lg' };
    };

    const onCloseModalConfirmation = useCallback(() => {
        onCloseModal();
        setIsFiltering(false);
        setStatusToggle(false);
    }, [onCloseModal]);

    const onConfirm = useCallback(
        () => {
            onOpen(0);
            setAction('confirm');
        },
        [onOpen],
    );

    useEffect(() => {
        setValue(Number(selectedPayments.reduce((acc, pay) => acc + Number(pay.total), 0)));
    }, [selectedPayments]);

    return (
        <>
            <Table<PaymentsToApprove>
                {...tableControllers}
                numberOfVisibleColumns={10}
                title={`Encontramos ${payments?.rowsCount ?? 0} pagos por aprobar`}
                columns={columns}
                rows={payments?.result ?? []}
                error={!!error}
                loading={loading}
                toggleOptions={getToggleOptions()}
                exportOptions={
                    {
                        content: (<Button
                            onClick={() => { }}
                            type="button" variant="outlined" color="info">
                            {`Total: $ ` + (value % 1 === 0 ? Math.floor(value).toLocaleString() : value.toLocaleString())}
                        </Button>),
                    }
                }
                filterComponent={
                    <form noValidate onSubmit={handleSubmit(onSubmit)} onKeyDown={
                        (event) => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                event.stopPropagation();
                                handleSubmit(onSubmit)();
                            }
                        }
                    }>
                        <Grid container alignItems="center" justify="flex-end" spacing={2} wrap="nowrap">
                            <Grid item xs={2}>
                                <Controller
                                    name="projectId"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <Select
                                                value={field.value
                                                }
                                                onChange={field.onChange}
                                                label="Proyectos"
                                                fullWidth
                                                multiple
                                                enableFilter
                                            >
                                                {projectsFetch ?
                                                    projectsFetch
                                                        .sort((proj1, proj2) => (proj1.name > proj2.name) ? 1 : -1)
                                                        .map(({ id, name }) => (
                                                            <MenuItem
                                                                key={name}
                                                                label={`${name}`}
                                                                value={`${id}`}
                                                            >
                                                                {name}
                                                            </MenuItem>
                                                        )) : <></>}
                                            </Select>
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Controller
                                    name="companyServiceId"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <Select
                                                value={field.value
                                                }
                                                onChange={field.onChange}
                                                label="Compañía"
                                                fullWidth
                                                multiple
                                                enableFilter
                                            >
                                                {companiesFetch ?
                                                    companiesFetch
                                                        .sort((comp1, comp2) => (comp1.name > comp2.name) ? 1 : -1)
                                                        .map(({ id, name }) => (
                                                            <MenuItem
                                                                key={name}
                                                                label={`${name}`}
                                                                value={`${id}`}
                                                            >
                                                                {name}
                                                            </MenuItem>
                                                        )) : <></>}
                                            </Select>
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Input
                                    fullWidth
                                    label={'Numero de radicado'}
                                    {...register("idReferenceRadication")}
                                    error={!!errors["idReferenceRadication"]}
                                    helperText={errors["idReferenceRadication"]?.message}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Input
                                    fullWidth
                                    label={'CP'}
                                    {...register("accountsPayable")}
                                    error={!!errors["accountsPayable"]}
                                    helperText={errors["accountsPayable"]?.message}
                                />
                            </Grid>
                            <Grid item>
                                <Button type="submit" variant="contained" color="primary">
                                    Filtrar
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={onConfirm}
                                    type="button" variant="contained" color="primary" disabled={selectedPayments.length === 0}>
                                    Pagar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                }
            />
            <Dialog
                open={selected !== undefined}
                onClose={onCloseModal}
                title={dialogProps().title}
                maxWidth={dialogProps().size}
            >
                <Grid container spacing={3} justify="flex-end">
                    <Grid item xs={12}>
                        {action === 'confirm' && (
                            <PaymentsToApproveConfirmationTemplate
                                setOpen={setOpen}
                                onClose={onCloseModalConfirmation}
                                setErrorMessage={setErrorMessage}
                                listPayments={selectedPayments}
                                setSelectedPayments={setSelectedPayments}
                                setActionSnackBar={setActionSnackBar}
                                filterModel={filterModel}
                                setFilterModel={setFilterModel}
                                isFiltering={isFiltering}
                                currentFilterModel={filterModel}
                            />
                        )}
                    </Grid>
                </Grid>
            </Dialog>
            <SnackBar
                wait={4000}
                open={open !== undefined}
                onClose={onCloseSnackBar}
                severity={open}
            >
                {open === 'success' && 'El proceso se realizó correctamente'}
                {open === 'warning' && errorMessage}
                {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
            </SnackBar>
        </>
    );
};

export default ListPaymentsToApproveTemplate;