import axios from "axios";
import { UPDATE_PAYMENTS } from "const";
import { updatePaymentsProps } from "./PaymentsToApproveConfirmationTemplate.types";

export const updatePayments = async (
    { listPayments, setSelectedPayments, setOpen, setErrorMessage, setActionSnackBar, setIsUpdating, paymentData }: updatePaymentsProps
) => {
    const ids = listPayments.map(item => item.idReferenceRadication);
    return axios.patch(UPDATE_PAYMENTS, {
        idPaymentMethod: parseInt(paymentData?.paymentMethod ?? '0'),
        idBankAccount: parseInt(paymentData?.bankAccount?.toString() ?? '0'),
        radicationReferences: ids
    }).then(() => {
        setOpen('success');
        setActionSnackBar('activate');
    }).catch((error) => {
        setOpen('error');
        if (error.response && error.response.data && error.response.data.message) {
            setErrorMessage(error.response.data.message);
            setOpen('warning');
        } else if (error.error) {
            setErrorMessage(error.error);
            setOpen('warning');
        } else {
            setErrorMessage(undefined);
            setOpen('error');
        }
    }).finally(() => {
        setSelectedPayments([]);
        setIsUpdating(false);
    });
}