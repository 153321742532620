import { TypeOf, array, number, object, string } from 'yup';

export const EditGroupingSchema = object({
  name: string(),
  projectName: string(),
  area: string(),
  status: string(),
  companyServiceGroupings: array().of(
    object({
      companyServiceGroupingId: number().nullable().optional(), // Permitir null
      reference: string().nullable().optional(), // Permitir null
      companyServiceCode: string().nullable().optional(), // Permitir null
    }),
  ),
});

export type EditGroupingType = TypeOf<typeof EditGroupingSchema>;

// The following two types were implemented to use internal types of the hook library.
export type FormValues = {
  name: string | undefined;
  projectName: string | undefined;
  area: string | undefined;
  status: string | undefined;
  companyServiceGroupings: Array<{}>;
};

export type CompanyServiceGroupingField =
  `companyServiceGroupings.${number}.companyServiceCode`;
