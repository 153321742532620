import { Customers } from 'types/customer';
import { date, object, string, TypeOf } from 'yup';

export const HistoryTemplateFilterSchema = object({
  isDownloaded: string(),
  invoiceNumber: string(),
  accountsPayable: string(),
  area: string(),
  projects: string(),
  publicServices: string(),
  dateRange: object({
    startDate: date().required(),
    endDate: date().required(),
  }).required(),
  action: string().optional(),
  customer: string().oneOf(Customers).optional(),
});

export type HistoryTemplateFilterType = TypeOf<
  typeof HistoryTemplateFilterSchema
>;
