import axios from "axios";
import { UPDATE_HISTORY_STATUS } from "const";
import { FlatHistory } from "types";
import { CLIENT_ACTIONS } from "../HistoryPendingTemplate/ClientActions.const";
import { updateHistoryProps } from "./HistoryConfirmationClientActionTemplate.types";

export const updateHistories = async (
    { listHistory, setModifiedHistory, setOpen, setErrorMessage, setActionSnackBar, setIsUpdating, action, customer }: updateHistoryProps
) => {
    const ids = getIdHistorias(listHistory);
    return axios.patch(
        UPDATE_HISTORY_STATUS,
        {
            historyIds: ids,
            status: CLIENT_ACTIONS?.[customer]?.[action].state_to_update,
        },
    ).then(() => {
        setOpen('success');
        setActionSnackBar('activate');
    }).catch((error) => {
        setOpen('error');
        console.log(error);
        if (error.response && error.response.data && error.response.data.message) {
            setErrorMessage(error.response.data.message);
            setOpen('warning');
        } else {
            setErrorMessage(undefined);
            setOpen('error');
        }
    }).finally(() => {
        setModifiedHistory([]);
        setIsUpdating(false);
    });
}

const getIdHistorias = (listHistory: FlatHistory[]) => {
    return listHistory.map(item => item.id);
}