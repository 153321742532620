import { withAuth } from 'HOCs';
import { Header } from 'components/molecules';
import { useParams } from 'react-router-dom';
import { HistoryPendingTemplate } from '../../../../components/templates/PublicServicesTemplate';


const breadcrumbs = [{ label: 'Servicios públicos' }];

const HistoryPendingPage = () => {
  const { action } = useParams();

  return (
    <>
      <Header
        title={"Pagos pendientes" + (action ? ` por ${action}` : "")}
        subtitle={"Lista y descarga registros de pagos pendientes" + (action ? ` por ${action}` : "")}
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <HistoryPendingTemplate />
    </>
  );
}

export default withAuth(HistoryPendingPage);