import { IconButton } from 'components/atoms';
import Toggle from 'components/atoms/Toggle';
import { columnType } from 'components/molecules';
import { paginationModelType } from 'components/organisms';
import { filterModelType } from 'hooks/useTable';
import { AiOutlineEye } from 'react-icons/ai';
import { PaymentsToApprove } from 'types';
import { createFilterStructure, formatDate } from 'utils';
import { ListPaymentsToApproveTemplateFilterType } from './ListPaymentsToApproveTemplate.schema';

export const defaultPagination: paginationModelType = {
    page: 0,
    rowsPerPage: 15,
    rowsCount: 0,
};

export const defaultFilter: ListPaymentsToApproveTemplateFilterType = {
    projectId: '',
    companyServiceId: '',
    accountsPayable: '',
    idReferenceRadication: '',
};

export const defaultStatusFilter: filterModelType<PaymentsToApprove>[] = [
    {
        field: 'code',
        type: 'equals',
        value: "accounts_payable_accounted",
        table: 'status'
    },
]

export const getColumns: (
    selectedPayments: PaymentsToApprove[],
    onCheck: (newState: boolean, sourceElement?: number | string | number[] | string[]) => void,
) => columnType<PaymentsToApprove>[] = (selectedPayments, onCheck) => {

    return [
        {
            field: 'projectName',
            headerName: 'Proyecto',
            flex: '1',
            exportField: true,
            render: ({ projectName }) => (<span title={projectName}>{projectName}</span>),
        },
        {
            field: 'companyServiceCode',
            headerName: 'Compañia',
            flex: '2',
            exportField: true,
            render: ({ companyServiceCode }) => (<span title={companyServiceCode}>{companyServiceCode}</span>),
        },
        {
            field: 'idReferenceRadication',
            headerName: 'Número de radicado',
            flex: '2',
            exportField: true,
            render: ({ idReferenceRadication }) => (<span title={idReferenceRadication}>{idReferenceRadication}</span>),
        },
        {
            field: 'accountsPayable',
            headerName: 'CP',
            flex: '1',
            exportField: true,
            render: ({ accountsPayable }) => (<span title={accountsPayable}>{accountsPayable}</span>),
        },
        {
            field: 'date',
            headerName: 'Fecha Límite',
            flex: '1',
            disabledSort: true,
            exportField: true,
            render: ({ date }) => (<span title={formatDate(date, 'DD/MM/YYYY')}>{formatDate(date, 'DD/MM/YYYY')}</span>),
        },
        {
            field: 'total',
            headerName: 'Valor Total',
            flex: '1',
            exportField: true,
            render: ({ total }) => (<span title={total.toString()}>{total % 1 === 0 ? Math.floor(total).toLocaleString() : total.toLocaleString()}</span>),
        },
        {
            field: 'selected',
            headerName: 'Aprobar pago',
            flex: '2',
            overflowNone: true,
            isState: true,
            enableToggle: true,
            disabledSort: true,
            render: ({ id }) => (
                <Toggle
                    isChecked={selectedPayments.find(payment => payment.id === id) ? true : false}
                    onToggleChange={onCheck}
                    sourceElement={id}
                    toggleSize='sm'
                />
            ),
        },
        {
            field: 'actions',
            headerName: 'Acciones',
            disabledSort: true,
            flex: '1',
            align: 'flex-start',
            render: (payment) => (
                <IconButton
                    info="Ver facturas asociadas"
                    positionEdge="start"
                    color="grey"
                    href={`/pages/servicios-publicos/reportes/pendientes/cp/${payment.accountsPayable}`}
                >
                    <AiOutlineEye />
                </IconButton>
            )
        }
    ]
};

export const createFilterModel = ({ accountsPayable, companyServiceId, projectId, idReferenceRadication }: ListPaymentsToApproveTemplateFilterType) => {

    const filterAccountsPayable = createFilterStructure(
        'accountsPayable',
        'contains',
        accountsPayable,
    );

    const filterIdReferenceRadication = createFilterStructure(
        'idReferenceRadication',
        'contains',
        idReferenceRadication,
    );

    let modelFilter = [
        filterAccountsPayable,
        filterIdReferenceRadication
    ]

    if (companyServiceId) {
        const filterCompanyServiceId = createFilterStructure(
            'id',
            'in',
            companyServiceId?.split(','), // CAMBIARLO POR EL ID
            'companyService'
        );
        modelFilter.push(filterCompanyServiceId);
    }

    if (projectId) {
        const filterprojectId = createFilterStructure(
            'id',
            'in',
            projectId?.split(','), // CAMBIARLO POR EL ID
            'project'
        );
        modelFilter.push(filterprojectId);
    }

    return modelFilter;
}

export const updatePayments = (
    setSelectedPayments: (value: React.SetStateAction<PaymentsToApprove[]>) => void,
    newPayments: PaymentsToApprove[]
) => {
    setSelectedPayments((payments) => {
        const updatedPayments = [...payments];

        newPayments.forEach(payment => {
            const existingIndex = payments.findIndex(g => g.id === payment.id);
            if (existingIndex > -1) {
                // If the exception already exists in modifiedPayments, update its status
                updatedPayments[existingIndex] = payment;
            } else {
                // If the exception doesn't exist in modifiedPayments, add it
                updatedPayments.push(payment);
            }
        });
        return updatedPayments;
    });
}