import { yupResolver } from '@hookform/resolvers/yup';
import axios, { AxiosError } from 'axios';
import {
  Button,
  Grid,
  Input,
  MenuItem,
  Paper,
  Select,
  SnackBar
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import Toggle from 'components/atoms/Toggle';
import { GET_ALL_SERVERS } from 'const';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Server } from 'types';
import { OperatingSystems, Providers, serverMonitoringConfig } from '../Shared/shared.const';
import CreateEditServerTemplateLoading from './CreateEditServerTemplate.loading';
import { CreateEditServerSchema, CreateEditServerType } from './CreateEditServerTemplate.schema';
import { CreateEditServerTemplateProps } from './CreateEditServerTemplate.types';
const CreateEditServerTemplate = ({ ...props }: CreateEditServerTemplateProps) => {

  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | undefined>();
  const navigate = useNavigate();
  const loadingData = loading;
  const [open, setOpen] = useState<severityType>();

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/monitoring/servers');
  };

  useEffect(() => {
    const fetchServerById = async () => {
      if (id) {
        try {
          setLoading(true);
          const response = await axios.get(`${GET_ALL_SERVERS}?filter=[{"field":"id","value":"${id}","type":"equals"}]`, serverMonitoringConfig);
          const server: Server = response.data.result[0];

          let channerUrl = '';
          // encodeURI(server.channerUrl)
          if (server.channelIdTeams && server.teamIdTeams) {
            channerUrl = `https://teams.microsoft.com/l/channel/${encodeURIComponent(server.channelIdTeams)}/default?groupId=${encodeURIComponent(server.teamIdTeams)}&tenantId=default`;
          }

          reset({
            name: server.name,
            ip: server.ip,
            domain: server.domain,
            timeout: server.timeout,
            provider: server.provider,
            operatingSystem: server.operatingSystem,
            supportEmail: server.supportEmail || '',
            maxRetry: server.maxRetry,
            disabled: server.disabled,
            channelIdTeams: server.channelIdTeams,
            teamIdTeams: server.teamIdTeams,
            channerUrl,
          })
          setLoading(false);
        } catch (error) {
          const axiosError = error as AxiosError;
          setOpen('error');
          setMessage(axiosError.message);
          setTimeout(() => {
            navigate('/pages/monitoring/servers');
          }, 1000);
        }
      }
    };
    fetchServerById();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm<CreateEditServerType>({
    resolver: yupResolver(CreateEditServerSchema),
    defaultValues: {
      name: '',
      ip: '',
      domain: '',
      timeout: 10000,
      provider: '',
      operatingSystem: '',
      supportEmail: '',
      maxRetry: 10,
      disabled: false,
      channelIdTeams: null,
      teamIdTeams: null,
      channerUrl: null,
    },
  });

  const isDisabled = () => {
    const { name, ip, domain, timeout, provider, operatingSystem, supportEmail, maxRetry } = getValues();

    // Retorna true si alguno de los campos esenciales está vacío o si no hay método de contacto
    return !name || !ip || !domain || !timeout || !provider || !operatingSystem || !maxRetry || !supportEmail;
  };

  const onSubmit = async (serverForm: CreateEditServerType) => {
    try {
      setLoading(true);
      const { ...form } = serverForm;
      delete form.channerUrl;
      const url = id ? `${GET_ALL_SERVERS}/${id}` : `${GET_ALL_SERVERS}`;
      if (id) {
        await axios.put<CreateEditServerType>(url, {
          ...form,
        }, serverMonitoringConfig); 
      } else {
        await axios.post<CreateEditServerType>(url, {
          ...form,
        }, serverMonitoringConfig);
      }
      setLoading(false);
      setMessage(id ? 'Servidor actualizado correctamente' : 'Servidor creado correctamente');
      setOpen('success');
    } catch (error) {
      const axiosError = error as AxiosError;
      setLoading(false);
      setOpen('error');
      setMessage(axiosError.response?.data.message ?? axiosError.message);
    }
  };

  useEffect(() => {
    const channerUrl = watch('channerUrl');

    const resetFields = (url: string | null, channelId: string | null, teamId: string | null) => {
      reset({
        ...getValues(),
        channerUrl: url,
        channelIdTeams: channelId,
        teamIdTeams: teamId,
      });
    };

    const handleInvalidUrl = () => {
      setMessage('URL de canal no válida');
      setOpen('warning');
      resetFields(null, null, null);
    };

    if (channerUrl?.match(/channel\/[^/]+\/[^/]+groupId=[^&]+&/)) {
      let decodedUrl = '';

      try {
        decodedUrl = decodeURIComponent(channerUrl);
      } catch (error) {
        return handleInvalidUrl();
      }

      const channelIdMatch = decodedUrl.match(/channel\/([^/]+)/);
      const groupIdMatch = decodedUrl.match(/groupId=([^&]+)/);

      const channelId = channelIdMatch?.[1] ?? '';
      const groupId = groupIdMatch?.[1] ?? '';

      if (channelId && groupId) {
        resetFields(decodedUrl, channelId, groupId);
      } else {
        handleInvalidUrl();
      }
    } else if (channerUrl) {
      handleInvalidUrl();
    } else {
      resetFields(null, null, null);
    }
  }, [watch('channerUrl')]);

  return (
    <Paper>
      {!loadingData ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={4}>
              <Input
                label="URL canal teams"
                value={watch('channerUrl') ?? ''}
                fullWidth
                {...register('channerUrl')}
                error={!!errors.channerUrl}
                helperText={errors.channerUrl && errors.channerUrl.message}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="ID Team"
                value={watch('teamIdTeams') ?? ''}
                fullWidth
                disabled
                {...register('teamIdTeams')}
                error={!!errors.teamIdTeams}
                helperText={errors.teamIdTeams && errors.teamIdTeams.message}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="ID del canal"
                value={watch('channelIdTeams') ?? ''}
                fullWidth
                disabled
                {...register('channelIdTeams')}
                error={!!errors.channelIdTeams}
                helperText={errors.channelIdTeams && errors.channelIdTeams.message}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} justify="flex-end">

            <Grid item xs={4}>
              <Input
                label="Nombre"
                value={watch('name')}
                fullWidth
                {...register('name')}
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="IP"
                value={watch('ip')}
                fullWidth
                {...register('ip')}
                error={!!errors.ip}
                helperText={errors.ip && errors.ip.message}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Dominio"
                value={watch('domain')}
                fullWidth
                {...register('domain')}
                error={!!errors.domain}
                helperText={errors.domain && errors.domain.message}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Timeout"
                value={watch('timeout')}
                fullWidth
                {...register('timeout')}
                error={!!errors.timeout}
                helperText={errors.timeout && errors.timeout.message}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="provider"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      value={field.value}
                      enableFilter={true}
                      onChange={field.onChange}
                      label="Proveedor"
                      fullWidth
                    >
                      {Providers ?
                        Providers.map((provider) => (
                          <MenuItem
                            key={provider}
                            label={`${provider}`}
                            value={`${provider}`}
                          >
                            {provider}
                          </MenuItem>
                        )) : <></>}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="operatingSystem"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      value={field.value}
                      enableFilter={true}
                      onChange={field.onChange}
                      label="Sistema operativo"
                      fullWidth
                    >
                      {OperatingSystems ?
                        OperatingSystems.map((os) => (
                          <MenuItem
                            key={os}
                            label={`${os}`}
                            value={`${os}`}
                          >
                            {os}
                          </MenuItem>
                        )) : <></>}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Email de soporte"
                value={watch('supportEmail') ?? ''}
                fullWidth
                {...register('supportEmail')}
                error={!!errors.supportEmail}
                helperText={errors.supportEmail && errors.supportEmail.message}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Maximo de reintentos"
                value={watch('maxRetry')}
                fullWidth
                {...register('maxRetry')}
                error={!!errors.maxRetry}
                helperText={errors.maxRetry && errors.maxRetry.message}
              />
            </Grid>
            <Grid item xs={4} alignItems='center' alignContent='center'>
              <Grid container spacing={0} alignItems='center'>
                <Grid item xs={3} >
                  <label>Deshabilitado</label>
                </Grid>
                <Grid item xs={6}>
                  <Toggle
                    isChecked={watch('disabled', false) as boolean}
                    {...register('disabled')}
                    onToggleChange={(value) => {
                      reset({ ...getValues(), disabled: value });
                    }}
                    tags={{ checked: 'Si', unchecked: 'No' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} justify="flex-end">
            <Grid item>
              <Button
                type='button'
                fullWidth
                variant="text"
                color="grey"
                href="/pages/monitoring/servers"
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
                disabled={isDisabled() && !id}
              >
                {id ? 'Actualizar' : 'Crear'}
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            wait={2000}
            open={open !== undefined}
            onClose={onClose}
            severity={open}
          >
            {message}
          </SnackBar>
        </form>
      ) : (
        <CreateEditServerTemplateLoading />
      )
      }
    </Paper >
  );
};

export default CreateEditServerTemplate;
