import { number, object, string, TypeOf } from 'yup';

export const PaymentsToApproveConfirmationSchema = object({
  paymentMethod: string().required(),
  bankAccount: number()
    .typeError('La cuenta bancaria debe ser un número')
    .required(),
});

export type PaymentsToApproveConfirmationType = TypeOf<
  typeof PaymentsToApproveConfirmationSchema
>;
