import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, MenuItem, Select, Typography } from 'components/atoms';
import { GET_BANCK_ACCOUNTS, GET_PAYMENT_METHODS } from 'const';
import { useGet } from 'hooks';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BanckAccount, PaymentMethod } from 'types';
import { Spinner } from "../../../../atoms";
import { updatePayments } from './PaymentsToApproveConfirmationTemplate.const';
import { PaymentsToApproveConfirmationSchema } from './PaymentsToApproveConfirmationTemplate.schema';
import { FormPaymentsToApproveTemplateProps } from './PaymentsToApproveConfirmationTemplate.types';

const PaymentsToApproveConfirmationTemplate = ({
  setOpen,
  onClose,
  setErrorMessage,
  listPayments,
  setSelectedPayments,
  setActionSnackBar,
  filterModel,
  setFilterModel,
  isFiltering,
  currentFilterModel,
  ...props
}: FormPaymentsToApproveTemplateProps) => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const { data: accountsFetch } = useGet<BanckAccount[]>(GET_BANCK_ACCOUNTS);
  const { data: methodsFetch } = useGet<PaymentMethod[]>(GET_PAYMENT_METHODS);
  const {
    handleSubmit,
    getValues,
    control,
  } = useForm({
    defaultValues: {
      bankAccount: undefined,
      paymentMethod: '',
    },
    resolver: yupResolver(PaymentsToApproveConfirmationSchema),
  });

  const onBatchUpdate = async () => {
    setIsUpdating(true);
    await updatePayments({
      listPayments,
      setSelectedPayments,
      setOpen,
      setErrorMessage,
      setActionSnackBar,
      setIsUpdating,
      paymentData: getValues(),
    });
    setIsUpdating(false);
    setOpen('success');
    setActionSnackBar('activate');
  };

  const onUpdate = async () => {
    await onBatchUpdate();
    setFilterModel([...currentFilterModel]);
    onClose();
  };

  const onDiscard = () => {
    try {
      setSelectedPayments([]);
      setFilterModel([...currentFilterModel]);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const getConfirmationText = () => {
    let isFilteringMessage = isFiltering ? 'Antes de filtrar los pagos' : '';
    const length = listPayments.length === 1 ? `del pago seleccionado` : `de los ${listPayments.length} pagos seleccionados`;
    const value = Number(listPayments.reduce((acc, pay) => acc + Number(pay.total), 0))
    const currencyFormat = value % 1 === 0 ? Math.floor(value).toLocaleString() : value.toLocaleString();

    return `${isFilteringMessage}¿Confirma el pago de ${currencyFormat} ${length}?`.replace(/\s/g, ' ');
  };

  return (
    <>
      {!isUpdating ?
        <form noValidate onSubmit={handleSubmit(() => onUpdate())}>
          <Grid container alignItems="flex-start" spacing={2} wrap="nowrap" direction='column' >
            <Grid item>
              <Typography variant='h2'>
                {getConfirmationText()}
              </Typography>
            </Grid>
            <Grid container spacing={3} justify='flex-end'>
              <Grid item xs={12} style={{ padding: 0, marginBottom: 20 }}>
                <Controller
                  name="paymentMethod"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        value={field.value}
                        onChange={field.onChange}
                        label="Forma de pago"
                        fullWidth
                        enableFilter
                      >
                        {methodsFetch ? methodsFetch.sort((a, b) => Number(a.code) - Number(b.code)).map((option) => (
                          <MenuItem key={option.code} value={option.id.toString()}>
                            {option.description}
                          </MenuItem>
                        )) : <></>}
                      </Select>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: 0, marginBottom: 20 }}>
                <Controller
                  name="bankAccount"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        value={field.value}
                        onChange={field.onChange}
                        label="Cuenta"
                        fullWidth
                        enableFilter
                      >
                        {accountsFetch ? accountsFetch.sort((a, b) => a.description.localeCompare(b.description)).map((option) => (
                          <MenuItem key={option.code} value={option.id.toString()}>
                            {option.description}
                          </MenuItem>
                        )) : <></>}
                      </Select>
                    );
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  info="Aceptar"
                  color="primary"
                  variant="contained"
                  type='submit'
                >
                  Aceptar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  info="Cancelar"
                  color="primary"
                  variant="contained"
                  onClick={() => onDiscard()}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
        : <Spinner></Spinner>
      }
    </>
  );
};

export default PaymentsToApproveConfirmationTemplate;
